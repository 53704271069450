<template>
  <div class="orders">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <b-button class="returnOrder" @click="movement('returned')"
      >Devolvidas</b-button
    >
    <b-button class="lostedOrder" @click="movement('lost')">Perdidas</b-button>
    <b-button class="waitAddress" @click="movement('pendent')"
      >Pendentes</b-button
    >
    <b-button class="processing" @click="movement('validate')"
      >Validar Morada</b-button
    >
    <b-button class="processing" @click="movement('picking')">Picking</b-button>
    <b-button class="printing" @click="movement('printing')">Printing</b-button>
    <b-button class="finish" @click="movement('packing')">Packing</b-button>
    <b-button class="completed" @click="movement('search')">Pesquisa</b-button>
    <!-- <b-button class="completed" @click="getAllOrdersCompleted">Completas</b-button>
        <b-button class="canceled" @click="getAllOrdersCancelled">Canceladas</b-button> -->
    <b-button class="canceled" @click="processingOrders">🔁</b-button>
    <div class="col">
      <button class="btn btn-danger btn-sm" v-b-modal.modal-4>
        Cancelar Encomenda
      </button>
    </div>
    <div v-if="this.page == 'pendent'">
      <br />
      <ordersPendent :key="reload" />
    </div>
    <div v-else-if="this.page == 'lost'">
      <br />
      <ordersLost :key="reload" />
    </div>
    <div v-else-if="this.page == 'validate'">
      <br />
      <ordersValidateAddress :key="reload" />
    </div>
    <div v-else-if="this.page == 'picking'">
      <br />
      <ordersPicking :key="reload" />
    </div>
    <div v-else-if="this.page == 'printing'">
      <br />
      <ordersPrinting :key="reload" />
    </div>
    <div v-else-if="this.page == 'packing'">
      <br />
      <ordersPacking :key="reload" />
    </div>
    <div v-else-if="this.page == 'returned'">
      <br />
      <ordersReturned :key="reload" />
    </div>
    <div v-else-if="this.page == 'search'">
      <br />
      <ordersSearch :key="reload" />
    </div>
    <!-- ################## MODAIS ###################-->
    <!-- MODAL EDITAR MORADA -->
    <b-modal ref="my-modal" id="modal-1" title="Editar Morada" hide-footer>
      <form v-on:submit.prevent="updateAddressOrder()">
        <div class="form-row">
          <div class="col">
            <label for="first_name">Primeiro Nome</label>
            <input
              id="first_name"
              type="text"
              class="form-control"
              v-model="firstNameUpdate"
              disabled
            />
          </div>
          <div class="col">
            <label for="last_name">Apelido</label>
            <div class="input-group mb-3">
              <input
                id="last_name"
                type="text"
                class="form-control"
                v-model="lastNameUpdate"
                disabled
              />
              <div class="input-group-append">
                <span class="input-group-text"
                  ><a v-b-modal.modal-7>🔓</a></span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="addressInput">Morada:</label>
          <input
            type="text"
            class="form-control"
            id="addressInput"
            v-model="addressUpdate"
          />
        </div>
        <div class="form-group">
          <label for="cityInput">Cidade:</label>
          <input
            type="text"
            class="form-control"
            id="cityInput"
            v-model="cityUpdate"
          />
        </div>
        <div class="form-group">
          <label for="postalCodeInput">Código Postal:</label>
          <input
            type="text"
            class="form-control"
            id="postalCodeInput"
            v-model="postalCodeUpdate"
          />
        </div>
        <div class="form-group">
          <label for="stateInput">Estado:</label>
          <input
            type="text"
            class="form-control"
            id="stateInput"
            v-model="stateUpdate"
          />
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect1">País</label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="countryUpdate"
          >
            <option
              v-for="country in countries"
              :key="country.code"
              :value="country.code"
            >
              {{ country.code }} ({{ country.name }})
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="stateInput">Comentários:</label>
          <textarea class="form-control" rows="2" v-model="note"></textarea>
        </div>
        <div id="btnUpdateAddress">
          <button type="submit" class="btn btn-primary">
            Guardar Alterações
          </button>
        </div>
      </form>
    </b-modal>
    <!-- MODAL EDITAR CONTACTO -->
    <b-modal
      ref="modal_contact"
      id="modal-2"
      title="Editar Contacto"
      hide-footer
    >
      <form v-on:submit.prevent="updateContact()">
        <div class="form-group">
          <label for="contactInput">Email:</label>
          <div class="input-group mb-3">
            <input
              type="email"
              class="form-control"
              id="emailInput"
              v-model="emailUpdate"
              disabled
            />
            <div class="input-group-append">
              <span class="input-group-text"><a v-b-modal.modal-6>🔓</a></span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="contactInput">Contacto:</label>
          <input
            type="text"
            class="form-control"
            id="contactInput"
            v-model="contactUpdate"
          />
        </div>
        <div id="btnUpdateAddress">
          <button type="submit" class="btn btn-primary">
            Guardar Alterações
          </button>
        </div>
      </form>
    </b-modal>
    <!-- MODAL PARA MOSTRAR O PRODUTO -->
    <b-modal
      ref="modal_product"
      id="modal-3"
      :title="'Produto ' + this.skuProduct"
      size="lg"
      hide-footer
    >
      <div class="card">
        <b-container>
          <b-row align-v="center"
            ><b-col class="left">
              <!-- IMAGEM DO PRODUTO -->
              <img :src="imgProduct" />
            </b-col>
            <b-col>
              <p><span id="subtitle">STOCK: </span> {{ stockProduct }}</p>
              <p><span id="subtitle">EAN: </span> {{ eanProduct }}</p>
              <p><span id="subtitle">SKU: </span> {{ skuProduct }}</p>
              <p><span id="subtitle">HS Code: </span> {{ hsProduct }}</p>
              <p>
                <span id="subtitle">Notas Arm.: </span> {{ warehouseProduct }}
              </p>
              <p><span id="subtitle">Notas: </span> {{ notesProduct }}</p>
              <p><span id="subtitle">Peso: </span> {{ weightProduct }} (g)</p>
            </b-col></b-row
          >
        </b-container>
        <button class="btn btn-info" @click="openProdPage()">
          Editar Produto
        </button>
      </div>
    </b-modal>
    <!-- MODAL CANCELAR ENCOMENDA -->
    <b-modal
      ref="modal_cancel"
      id="modal-4"
      title="Cancelar Encomenda"
      hide-footer
    >
      <form v-on:submit.prevent="updateCancelled()">
        <div class="form-group">
          <label for="contactInput">Número Encomenda:</label>
          <input
            type="text"
            class="form-control"
            id="contactInput"
            v-model="orderToCancel"
          />
        </div>
        <div id="btnUpdateAddress">
          <button class="btn btn-primary">Confirmar</button>
        </div>
      </form>
    </b-modal>
    <!-- MODAL CS TRANSPORTADORA -->
    <b-modal
      id="modal-5"
      title="Selecionar distribuidora!"
      ref="modal_distribuidora"
      hide-footer
    >
      <form v-on:submit.prevent="select_company()">
        <div class="form-group">
          <label for="provider">Distribuidora</label>
          <v-select
            :options="companies"
            v-model="select_company_var"
            label="name"
          >
            <template slot="option" slot-scope="option">
              {{ option.name }}</template
            >
            <template slot="selected-option" slot-scope="option">
              {{ option.name }}</template
            >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="!select_company_var"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </b-modal>
    <b-modal id="modal-6" title="Editar Email" ref="modal_email" hide-footer>
      <div class="form-group">
        <label for="contactInput">Email:</label>
        <input
          type="text"
          class="form-control"
          id="contactInput"
          v-model="emailUpdate"
        />
      </div>
      <button type="click" class="btn btn-primary" @click="closeModal()">
        Mudar
      </button>
    </b-modal>
    <b-modal id="modal-7" title="Editar Nomes" ref="modal_email" hide-footer>
      <div class="form-group">
        <label for="contactInput">Primeiro Nome:</label>
        <input
          type="text"
          class="form-control"
          id="contactInput"
          v-model="firstNameUpdate"
        />
      </div>
      <div class="form-group">
        <label for="contactInput">Apelido:</label>
        <input
          type="text"
          class="form-control"
          id="contactInput"
          v-model="lastNameUpdate"
        />
      </div>
      <button type="click" class="btn btn-primary" @click="closeModal()">
        Mudar
      </button>
    </b-modal>
    <!-- MODAL CS DELAY -->
    <b-modal
      id="modal-8"
      title="Selecionar razão atraso!"
      ref="modal_delay"
      hide-footer
    >
      <form v-on:submit.prevent="send_email_delay()">
        <div class="form-group">
          <label for="provider">Opções</label>
          <v-select :options="reasons" v-model="select_reason" label="reason">
            <template slot="option" slot-scope="option">
              {{ option.reason }}</template
            >
            <template slot="selected-option" slot-scope="option">
              {{ option.reason }}</template
            >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="!select_reason"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </b-modal>
    <b-modal
      id="modal-9"
      title="Selecionar razão de cancelamento!"
      ref="modal_cancel_kk"
      hide-footer
    >
      <form @submit.prevent="cancelOrderKK()">
        <!-- Grupo de botões de rádio para as razões -->
        <b-form-radio-group v-model="selectedReason" name="reasons" stacked>
          <b-form-radio
            v-for="(reason, index) in cancellationReasons"
            :key="index"
            :value="reason"
          >
            {{ reason }}
          </b-form-radio>
        </b-form-radio-group>

        <!-- Botão de confirmação -->
        <div class="mt-3 text-right">
          <b-button type="submit" variant="primary">
            Confirmar Cancelamento
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

//Components
import Loading from '@/components/Loading.vue';
import ordersReturned from '../components/ordersReturned.vue';
import ordersPendent from '../components/ordersPendent.vue';
import ordersPicking from '../components/ordersPicking.vue';
import ordersPrinting from '../components/ordersPrinting.vue';
import ordersPacking from '../components/ordersPacking.vue';
import ordersSearch from '../components/ordersSearch.vue';
import ordersLost from '@/components/ordersLost.vue';
import ordersValidateAddress from '@/components/ordersValidateAddress';

export default {
  components: {
    Loading,
    ordersReturned,
    ordersPendent,
    ordersPicking,
    ordersPrinting,
    ordersPacking,
    ordersSearch,
    ordersLost,
    ordersValidateAddress,
  },
  data: function () {
    return {
      loading: false,
      page: '',
      data: '',
      countries: [],
      addressUpdate: '',
      cityUpdate: '',
      postalCodeUpdate: '',
      idOrderUpdate: '',
      trackingId: '',
      stateUpdate: '',
      countryUpdate: '',
      contactUpdate: '',
      firstNameUpdate: '',
      lastNameUpdate: '',
      allProducts: [],
      eanProduct: '',
      skuProduct: '',
      hsProduct: '',
      warehouseProduct: '',
      notesProduct: '',
      weightProduct: '',
      imgProduct: '',
      idProduct: '',
      stockProduct: '',
      orderToCancel: '',
      reasons: [],
      select_reason: '',
      reload: 0,
      notes: [],
      note: '',
      companies: [
        {
          name: 'DHL',
          code: 'dhl',
        },
        {
          name: 'FedEx',
          code: 'fedex',
        },
        {
          name: 'CTT',
          code: 'ctt',
        },
        {
          name: 'CTT Expresso',
          code: 'ctt expresso',
        },
        {
          name: 'CTT Azul',
          code: 'ctt azul',
        },
      ],
      select_company_var: '',
      id_cs: '',
      emailUpdate: '',
      //Shipping Info
      ship_weight: '',
      ship_height: '',
      ship_width: '',
      ship_length: '',
      ship_price: '',
      //KK
      cancellationReasons: [
        'Pedido do Cliente',
        'Ruptura do Stock',
        'Preço Errado',
        'Erro de Matching',
        'Prazo de Entrega',
        'Comercial',
      ],
      // Armazena a razão selecionada
      selectedReason: null,
      cancelIdKK: '',
    };
  },
  methods: {
    closeModal() {
      this.$refs['modal_email'].hide();
    },
    //Basic
    movement(new_page) {
      this.page = new_page;
    },
    routeToOrder(orderId) {
      // this.$router.push(`/orders/${orderId}`);
      window.open(
        `https://${window.location.host}/orders/${orderId}`,
        '_blank'
      );
    },
    async openProdPage() {
      window.open(
        `https://${window.location.host}/product/${this.idProduct}`,
        '_blank'
      );
      this.$refs['modal_product'].hide();
    },
    async processingOrders() {
      this.loading = true;
      try {
        await this.$store.dispatch('updateProducts');
        await this.$store.dispatch('addOrdersProcessing');
        await this.$store.dispatch('calculatePicking');
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    splitDate(date) {
      let split = date.split('T');
      return split[0];
    },
    calculateDifferenceBetweenDates(date) {
      let dateNow = new Date();
      let year = dateNow.getFullYear();
      let month = ('0' + (dateNow.getMonth() + 1)).slice(-2);
      let day = ('0' + dateNow.getDate()).slice(-2);
      let finalDateNow = `${year}-${month}-${day}`;
      let orderDate = this.splitDate(date);
      /* USAR BIBLIOTECA diff PARA COMPARAR AS DATAS */
      let diff = moment(finalDateNow).diff(orderDate, 'days');
      return Number(diff);
    },
    //Pendentes
    async confirmPendent(id) {
      this.$swal({
        title: 'Quer colocar a encomenda ' + id + ' no estado Pendente?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.setPendent(id);
          this.$swal('Completado', 'Estado alterado!', 'success');
        }
      });
    },
    async confirmPendentCS(id, current) {
      console.log('IDIDIDIDID', current);
      if (!current) {
        this.$swal({
          title: 'Quer colocar a encomenda ' + id + ' no estado Pendente?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.setPendentCS(id, true);
          }
        });
      } else {
        this.$swal({
          title: 'Quer retirar a encomenda ' + id + ' do estado Pendente?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.setPendentCS(id, false);
          }
        });
      }
    },
    async confirmPendentEdit(id) {
      this.$swal({
        title: 'Quer retirar o bloqueio após edição da encomenda ' + id + '?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.setPendentEdit(id);
        }
      });
    },
    async confirmPendentPayment(id) {
      this.$swal({
        title: 'Quer retirar o bloqueio de pagamento da encomenda ' + id + '?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.setPendentPayment(id);
        }
      });
    },
    async setPendent(id) {
      try {
        await this.$store.dispatch('setPendent', {
          id: id,
        });
        this.reload++;
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async setPendentCS(id, bool) {
      try {
        await this.$store.dispatch('setPendentCS', {
          id: id,
          bool: bool,
        });
        this.$swal('Completado', 'Estado alterado!', 'success');
        this.reload++;
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async setPendentEdit(id) {
      console.log('edição');
      try {
        await this.$store.dispatch('setPendentEdit', {
          id: id,
        });
        this.$swal('Completado', 'Estado alterado!', 'success');
        this.reload++;
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async setPendentPayment(id) {
      console.log('edição');
      try {
        await this.$store.dispatch('setPendentPayment', {
          id: id,
        });
        this.$swal('Completado', 'Estado alterado!', 'success');
        this.reload++;
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    //KK
    async acceptOrderKK(id) {
      this.$swal({
        title: 'Quer aceitar a encomenda ' + id + '?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('acceptOrderKK', {
              id: id,
            });
            this.$swal('Completado', 'Encomenda aceite!', 'success');
            this.reload++;
          } catch (error) {
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'error',
              title: error,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    },
    async openModalCancelKK(id) {
      this.cancelIdKK = id;
      this.$refs['modal_cancel_kk'].show();
    },
    async cancelOrderKK() {
      if (!this.selectedReason) {
        this.$swal('Por favor, selecione uma razão para cancelar o pedido.');
        return;
      }

      this.$swal({
        title: 'Quer cancelar a encomenda ' + this.cancelIdKK + '?',
        text: 'Razão selecionada: ' + this.selectedReason,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('cancelOrderKK', {
              id: this.cancelIdKK,
              reason: this.selectedReason,
            });
            this.$swal('Completado', 'Encomenda cancelada!', 'success');
            this.reload++;
          } catch (error) {
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'error',
              title: error,
              showConfirmButton: false,
              timer: 1500,
            });
          }

          // Fecha a modal após o cancelamento
          this.$refs.modal_cancel_kk.hide();
          this.selectedReason = null;
          this.cancelIdKK = '';
        }
      });
    },
    //Prioridades
    async confirmPriority(id) {
      this.$swal({
        title: 'Quer colocar a encomenda ' + id + ' com prioridade?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.setPriority(id);
          this.$swal('Completado', 'Prioritária!', 'success');
        } else {
          const index = this.ordersFiltered.findIndex(
            (order) => order.id == id
          );
          this.ordersFiltered[index].priority = false;
        }
      });
    },
    async setPriority(id) {
      try {
        await this.$store.dispatch('setPriority', {
          id: id,
        });
        this.reload++;
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    //---------Modais-------
    //Morada
    async passDataModal(data) {
      this.data = data;
      this.firstNameUpdate = data.first_name;
      this.lastNameUpdate = data.second_name;
      this.addressUpdate = data.adress1;
      this.cityUpdate = data.city;
      this.postalCodeUpdate = data.postcode;
      this.idOrderUpdate = data.id;
      this.stateUpdate = data.state;
      this.countryUpdate = data.country;
      if (data.notes != null && data.notes != undefined) {
        this.notes = data.notes;
      }
      this.$refs['my-modal'].show();
    },
    async updateAddressOrder() {
      if (this.note != '') {
        let user = JSON.parse(localStorage.getItem('user'));
        let obj = {
          user: user.username,
          comment: this.note,
        };
        console.log(obj);
        console.log(this.notes);
        this.notes.push(obj);
        this.note = '';
      }
      try {
        await this.$store.dispatch('updateAddress', {
          id: this.idOrderUpdate,
          firstName: this.firstNameUpdate,
          lastName: this.lastNameUpdate,
          address: this.addressUpdate,
          city: this.cityUpdate,
          postalCode: this.postalCodeUpdate,
          state: this.stateUpdate,
          country: this.countryUpdate,
          notes: this.notes,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Morada ${this.idOrderUpdate} Editada`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs['my-modal'].hide();
        this.reload++;
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    //Contacto
    async passContact(data) {
      this.data = data;
      this.emailUpdate = data.email;
      this.contactUpdate = data.phone;
      this.idOrderUpdate = data.id;
      console.log(data.phone);
      this.$refs['modal_contact'].show();
    },
    //Distribuidora
    async open_modal_company(data) {
      console.log(data);
      this.id_cs = data.id;
      this.$refs['modal_distribuidora'].show();
    },
    async select_company() {
      console.log(this.select_company);
      try {
        await this.$store.dispatch('cs_shipping_company', {
          id: this.id_cs,
          company: this.select_company_var,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Shipping editado`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs['modal_distribuidora'].hide();
        this.select_company_var = '';
        this.reload++;
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async updateContact() {
      try {
        await this.$store.dispatch('updateContact', {
          id: this.idOrderUpdate,
          contact: this.contactUpdate,
          email: this.emailUpdate,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Contactos ${this.idOrderUpdate} editado`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs['modal_contact'].hide();
        this.reload++;
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    //Cancelar
    async updateCancelled() {
      this.$swal({
        title: 'Quer cancelar a encomenda ' + this.orderToCancel + ' ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, cancelar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(this.orderToCancel);
            await this.$store.dispatch('updateStatus', {
              id: this.orderToCancel,
            });
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: `Encomenda ${this.orderToCancel} cancelada`,
              showConfirmButton: false,
              timer: 1500,
            });
            this.$refs['modal_cancel'].hide();
            this.reload++;
            this.orderToCancel = '';
            this.search = true;
          } catch (err) {
            console.log(err);
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'error',
              title: err,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    },
    //Atraso
    async open_modal_delay(id) {
      this.id_cs = id;
      this.$refs['modal_delay'].show();
    },
    async send_email_delay() {
      console.log(this.select_reason);
      try {
        await this.$store.dispatch('sendReasonsDelay', {
          id: this.id_cs,
          reason: this.select_reason.reason,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Email enviado`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs['modal_delay'].hide();
        this.select_reason = '';
        this.reload++;
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    //Produtos
    async passProductData(data) {
      console.log('INFOOOO', data);
      // const filterProduct = this.allProducts.filter((product) => product.sku.includes(data.sku));
      this.eanProduct = data.ean;
      this.skuProduct = data.sku;
      this.hsProduct = data.hscode;
      this.warehouseProduct = data.note_warehouse;
      this.notesProduct = data.note_finish;
      this.weightProduct = data.weight;
      this.imgProduct = data.img;
      this.idProduct = data.id_backoffice;
      this.stockProduct = data.stock;
      this.$refs['modal_product'].show();
    },
    //Gets
    async allCountries() {
      try {
        await this.$store.dispatch('fetchAllCountries');
        this.countries = this.getAllCountries;
        console.log(this.getAllCountries);
      } catch (err) {
        alert(err);
      }
    },
    async getAllProducts() {
      try {
        await this.$store.dispatch('fetchProducts');
        this.allProducts = this.getProducts.data;
        console.log('PRODUTOS: ', this.allProducts);
      } catch (err) {
        alert(err);
      }
    },
    async getReasons() {
      try {
        await this.$store.dispatch('getReasonsDelay');
        this.reasons = this.getReasonsDelay;
        console.log('RAZÕES: ', this.reasons);
      } catch (err) {
        alert(err);
      }
    },
    //Atualizar
    async updateChilds() {
      this.reload++;
    },
  },
  computed: {
    ...mapGetters(['getAllCountries']),
    ...mapGetters(['getProducts']),
    ...mapGetters(['getReasonsDelay']),
  },
  async created() {
    this.loading = true;
    console.log('CREATED');
    await this.allCountries();
    try {
      await this.$store.dispatch('calculatePicking');
    } catch (err) {
      alert(err);
    }
    // await this.getAllProducts();
    await this.getReasons();
    this.page = 'search';
    this.loading = false;
  },
};
</script>

<style scoped>
.orders {
  text-align: center;
}
.table {
  font-size: 12px;
  margin-top: 30px;
}
button {
  margin: 10px 5px 0px 5px;
  color: white;
}
.form-select {
  margin-bottom: 5px;
}
.returnOrder {
  margin: 30px 5px 0px 5px;
  background-color: #8fb1cd;
  border-color: #8fb1c1;
}
.returnOrder:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}
.lostedOrder {
  margin: 30px 5px 0px 5px;
  background-color: #7f9db6;
  border-color: #7f9db6;
}
.lostedOrder:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}
.waitAddress {
  margin: 30px 5px 0px 5px;
  background-color: #7097b4;
  border-color: #7097b4;
}
.waitAddress:hover {
  background-color: #527d9c;
  border-color: #527d9c;
}
.processing {
  margin: 30px 5px 0px 5px;
  background-color: #527d9c;
  border-color: #527d9c;
}
.processing:hover {
  background-color: #316484;
  border-color: #316484;
}
.printing {
  margin: 30px 5px 0px 5px;
  background-color: #316484;
  border-color: #316484;
}
.printing:hover {
  background-color: #004c6d;
  border-color: #004c6d;
}
.finish {
  margin: 30px 5px 0px 5px;
  background-color: #004c6d;
  border-color: #004c6d;
}
.finish:hover {
  background-color: #003d57;
  border-color: #003d57;
}

.completed {
  margin: 30px 5px 0px 5px;
  background-color: green;
  border-color: green;
}
.completed:hover {
  background-color: rgb(8, 97, 8);
  border-color: rgb(8, 97, 8);
}
.canceled {
  margin: 30px 5px 0px 5px;
  background-color: red;
  border-color: rgb(255, 0, 0);
}
.canceled:hover {
  background-color: rgb(175, 3, 3);
  border-color: rgb(175, 3, 3);
}
h2 {
  margin-top: 20px;
}

.th {
  background-color: thistle;
}
.searchbar {
  position: absolute;
  right: 0px;
  width: 200px;
  margin: 10px 20px 0px 0px;
}

span a p {
  font-size: 16px;
  text-decoration: underline;
}
span a p:hover {
  text-decoration: none;
  cursor: pointer;
}
#btnUpdateAddress {
  text-align: center;
}
#aUpdateAddress {
  margin-left: 5px;
  cursor: pointer;
}
#aUpdateAddress:hover {
  cursor: pointer;
}
img {
  max-width: 100%;
  border-radius: 18px;
}
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 10px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#subtitle {
  color: white;
  font-weight: bold;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
#setPendent {
  color: rgb(255, 0, 0);
}
#setPendent:hover {
  text-decoration: underline;
  cursor: pointer;
}
#setPriority {
  color: rgb(26, 100, 2);
}
#setPriority:hover {
  text-decoration: underline;
  cursor: pointer;
}
#filters {
  padding: 0px 100px 0px 100px;
}
</style>
