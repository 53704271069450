<template>
  <div>
    <div class="search">
      <div class="row" id="filters">
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Estado</span
              >
            </div>
            <select
              class="custom-select"
              id="inputGroupSelect01"
              v-model="status"
              @change="filterStatus()"
            >
              <option selected value="all">Todas</option>
              <option value="white">Branco (&#60; 2 dias)</option>
              <option value="blue">Azul (2 dias)</option>
              <option value="orange">Laranja (3 dias)</option>
              <option value="red">Vermelho (4 dias)</option>
              <option value="black">Preto (&#62; 4 dias)</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Email</span
              >
            </div>
            <input
              type="text"
              class="form-control"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              v-model="emailSearch"
              @change="filterbyEmail()"
            />
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Transportadora</span
              >
            </div>
            <select
              class="custom-select"
              id="inputGroupSelect01"
              v-model="transportadora"
              @change="select_trans()"
            >
              <option selected value="">Todas</option>
              <option value="ctt">CTT</option>
              <option value="cttexpresso">CTT Expresso</option>
              <option value="dhl">DHL</option>
              <option value="fedex">FedEx</option>
              <option value="free">Free Shipping</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Selecionar</span
              >
            </div>
            <select
              class="custom-select"
              id="inputGroupSelect01"
              v-model="select_print"
              @change="selected()"
            >
              <option selected value="">Sem pré-seleção</option>
              <option value="5">5 encomendas</option>
              <option value="10">10 encomendas</option>
              <option value="15">15 encomendas</option>
              <option value="20">20 encomendas</option>
            </select>
          </div>
        </div>
        <div class="col">
          <button
            class="btn btn-primary"
            style="margin-right: 10px"
            @click="imprimir()"
          >
            Imprimir
          </button>
          <span> </span>
          <button class="btn btn-secondary" @click="scrollProcessamento()">
            Processamento
          </button>
        </div>
      </div>
    </div>

    <h1>Picking Pronto</h1>
    <b-table
      ref="picking"
      striped
      hover
      :items="pickingFilteredOrders"
      :fields="fields"
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      class="table"
      small
      select-mode="multi"
      thead-class="text-white"
    >
      <template #cell(id)="data">
        <b-form-checkbox
          v-if="select_print == ''"
          @change="checked(data.index, data.rowSelected)"
          v-model="data.rowSelected"
          ref="select_checkbox"
        >
        </b-form-checkbox>
        <b-link @click="routeToOrder(data.item.id)">
          <span
            v-if="data.item.priority == true"
            style="background-color: green; color: white"
            >{{ data.item.id }}</span
          >
          <span v-else>{{ data.item.id }}</span>
        </b-link>
      </template>
      <template #cell(date)="data">
        <a
          v-if="data.item.send_email_address != true"
          id="setPriority"
          @click="open_modal_delay(data.item.id)"
          >Atraso</a
        >
        <br />
        <span
          v-if="calculateDifferenceBetweenDates(data.item.date) == 2"
          style="background: blue; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 3"
          style="background: orange; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 4"
          style="background: red; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) > 4"
          style="background: black; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else style="background: white">
          {{ splitDate(data.item.date) }}
        </span>
        <br />
        <a
          v-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == true
          "
        >
          <div
            :class="{ 'red-square': isAutoCaptureLate(data.item.auto_capture) }"
          >
            Auto captura: {{ splitDate(data.item.auto_capture) }}
          </div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
        <a
          v-else-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == false
          "
        >
          <div class="green-square">Capturado</div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
      </template>
      <template #cell(articles)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span
          v-for="article in JSON.parse(data.item.articles)"
          :key="article.id"
        >
          <p>
            {{ article.quantity }} x {{ article.sku }} -
            <a
              id="openArticle"
              v-b-modal.modal-3
              @click="passProductData(article)"
              >{{ article.name }}
            </a>
            - {{ article.price_eur }} € -
            <a v-if="article.picked == article.ordered">(✔️)</a>
            <a v-else style="color: red"
              >(Pick: {{ article.picked }} |<a style="color: black"
                >| Stock: {{ article.stock }}</a
              >)</a
            >
          </p>
        </span>
      </template>
      <template #cell(client)="data">
        <span>
          <p>
            <b>Nome:</b> {{ data.item.first_name }}
            {{ data.item.second_name }}
            <br />
            {{ data.item.adress1 }} {{ data.item.adress2 }} <br />
            {{ data.item.city }} - {{ data.item.postcode }}<br />
            {{ data.item.state }} - {{ data.item.country
            }}<!-- <b-button
                      id="btnUpdateAddress"
                      v-b-modal.modal-1
                      @click="passDataModal(data.item)"
                      >✒️</b-button
                      > -->
            <a
              id="aUpdateAddress"
              v-b-modal.modal-1
              @click="passDataModal(data.item)"
              >✒️</a
            >
            <br />
            <a
              v-if="
                data.item.send_email_address == true &&
                data.item.status == 'processing'
              "
              style="color: white; background-color: blueviolet"
              >Pendente Morada</a
            >
            <a
              v-else-if="
                data.item.cs_lock == true && data.item.status == 'processing'
              "
              style="color: white; background-color: blueviolet"
              >Pendente CS</a
            >
          </p>
        </span>
        <span> </span>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.item.email }} <br />
          {{ data.item.phone }} <br />
          <a
            id="aUpdateAddress"
            v-b-modal.modal-2
            @click="passContact(data.item)"
            >📱</a
          >
          <br />
          <span style="font-size: smaller; color: red">
            {{ data.item.customer_note }}
          </span>
        </span>
      </template>
      <template #cell(company)="data">
        <span>
          <p>
            {{ data.item.shipping_company.split('-')[0] }}
          </p>
          <p>({{ data.item.company_select }})</p>
          <a
            id="aUpdateAddress"
            v-b-modal.modal-5
            @click="open_modal_company(data.item)"
            >🔧</a
          >
        </span>
      </template>
      <template #cell(actions)="data">
        <span>
          <p
            v-if="
              data.item.send_email_address != true &&
              data.item.status == 'processing' &&
              data.item.cs_lock != true
            "
            id="setPendent"
            @click="confirmPendent(data.item.id)"
          >
            Morada Errada
          </p>
          <p
            v-if="
              data.item.status == 'processing' &&
              data.item.cs_lock != true &&
              data.item.send_email_address != true
            "
            id="setPendentCS"
            @click="confirmPendentCS(data.item.id, data.item.cs_lock)"
          >
            Bloquear CS
          </p>
          <p
            v-else-if="
              data.item.status == 'processing' &&
              data.item.cs_lock == true &&
              data.item.send_email_address != true
            "
            id="setPendentCS"
            @click="confirmPendentCS(data.item.id, data.item.cs_lock)"
          >
            Desbloquear CS
          </p>
          <p v-else>Já está bloqueado</p>
        </span>
      </template>
    </b-table>
    <h1 ref="processamento">Processamento</h1>
    <b-table
      striped
      hover
      :items="processingFilteredOrders"
      :fields="fields"
      :filter="filter"
      :per-page="perPage2"
      :current-page="currentPage2"
      class="table"
      small
      thead-class="text-white"
    >
      <template #cell(id)="data">
        <b-link @click="routeToOrder(data.item.id)">
          <span
            v-if="data.item.priority == true"
            style="background-color: green; color: white"
            >{{ data.item.id }}</span
          >
          <span v-else>{{ data.item.id }}</span>
        </b-link>
      </template>
      <template #cell(date)="data">
        <a
          v-if="data.item.send_email_address != true"
          id="setPriority"
          @click="open_modal_delay(data.item.id)"
          >Atraso</a
        >
        <br />
        <span
          v-if="calculateDifferenceBetweenDates(data.item.date) == 2"
          style="background: blue; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 3"
          style="background: orange; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 4"
          style="background: red; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) > 4"
          style="background: black; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else style="background: white">
          {{ splitDate(data.item.date) }}
        </span>
        <br />
        <span
          v-if="data.item.priority == false"
          id="setPriority"
          @click="confirmPriority(data.item.id)"
        >
          Tornar Prioritário
        </span>
        <br />
        <a
          v-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == true
          "
        >
          <div
            :class="{ 'red-square': isAutoCaptureLate(data.item.auto_capture) }"
          >
            Auto captura: {{ splitDate(data.item.auto_capture) }}
          </div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
        <a
          v-else-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == false
          "
        >
          <div class="green-square">Capturado</div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
      </template>
      <template #cell(articles)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span
          v-for="article in JSON.parse(data.item.articles)"
          :key="article.id"
        >
          <p>
            {{ article.quantity }} x {{ article.sku }} -
            <a
              id="openArticle"
              v-b-modal.modal-3
              @click="passProductData(article)"
              >{{ article.name }}
            </a>
            - {{ article.price_eur }} € -
            <a v-if="article.picked == article.ordered">(✔️)</a>
            <a v-else style="color: red"
              >(Pick: {{ article.picked }} |<a style="color: black"
                >| Stock: {{ article.stock }}</a
              >)</a
            >
          </p>
        </span>
      </template>
      <template #cell(client)="data">
        <span>
          <p>
            <b>Nome:</b> {{ data.item.first_name }}
            {{ data.item.second_name }}
            <br />
            {{ data.item.adress1 }} {{ data.item.adress2 }} <br />
            {{ data.item.city }} - {{ data.item.postcode }}<br />
            {{ data.item.state }} - {{ data.item.country
            }}<!-- <b-button
                        id="btnUpdateAddress"
                        v-b-modal.modal-1
                        @click="passDataModal(data.item)"
                        >✒️</b-button
                        > -->
            <a
              id="aUpdateAddress"
              v-b-modal.modal-1
              @click="passDataModal(data.item)"
              >✒️</a
            >
            <br />
            <a
              v-if="
                data.item.send_email_address == true &&
                data.item.status == 'processing'
              "
              style="color: white; background-color: blueviolet"
              >Pendente Morada</a
            >
            <a
              v-else-if="
                data.item.cs_lock == true && data.item.status == 'processing'
              "
              style="color: white; background-color: blueviolet"
              >Pendente CS</a
            >
            <a
              v-else-if="
                data.item.edit_lock == true && data.item.status == 'processing'
              "
              style="color: white; background-color: blueviolet"
              >Pendente Edição</a
            >
          </p>
        </span>
        <span> </span>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.item.email }} <br />
          {{ data.item.phone }} <br />
          <a
            id="aUpdateAddress"
            v-b-modal.modal-2
            @click="passContact(data.item)"
            >📱</a
          >
        </span>
        <br />
        <span style="font-size: smaller; color: red">
          {{ data.item.customer_note }}
        </span>
      </template>
      <template #cell(company)="data">
        <span>
          <p>
            {{ data.item.shipping_company.split('-')[0] }}
          </p>
          <p>({{ data.item.company_select }})</p>
          <a
            id="aUpdateAddress"
            v-b-modal.modal-5
            @click="open_modal_company(data.item)"
            >🔧</a
          >
        </span>
      </template>
      <template #cell(actions)="data">
        <span>
          <!-- Verifica se o status é "processing" e o email ainda não foi enviado -->
          <template
            v-if="
              data.item.status === 'processing' && !data.item.send_email_address
            "
          >
            <!-- 1. Se kk_pending estiver ativo, exibe os botões de Aceitar/Cancelar -->
            <template v-if="data.item.kk_pending">
              <span>
                <button @click="acceptOrderKK(data.item.id)" class="btn-accept">
                  Aceitar
                </button>
                <button @click="cancelOrderKK(data.item.id)" class="btn-cancel">
                  Cancelar
                </button>
              </span>
            </template>

            <!-- 2. Se kk_pending não estiver ativo, avalia os demais estados -->
            <template v-else>
              <!-- 2.1. Se não houver bloqueios (nem CS, nem Edição, nem Pagamento) -->
              <template
                v-if="
                  !data.item.cs_lock &&
                  !data.item.edit_lock &&
                  !data.item.payment_lock
                "
              >
                <p id="setPendent" @click="confirmPendent(data.item.id)">
                  Morada Errada
                </p>
                <p
                  id="setPendentCS"
                  @click="confirmPendentCS(data.item.id, data.item.cs_lock)"
                >
                  Bloquear CS
                </p>
              </template>

              <!-- 2.2. Se o CS estiver bloqueado -->
              <template v-else-if="data.item.cs_lock">
                <p
                  id="setPendentCS"
                  @click="confirmPendentCS(data.item.id, data.item.cs_lock)"
                >
                  Desbloquear CS
                </p>
              </template>

              <!-- 2.3. Se a Edição estiver bloqueada (e o CS não estiver bloqueado) -->
              <template v-else-if="data.item.edit_lock && !data.item.cs_lock">
                <p
                  id="setPendentCS"
                  @click="confirmPendentEdit(data.item.id, data.item.cs_lock)"
                >
                  Desbloquear Edição
                </p>
              </template>

              <!-- 2.4. Se o Pagamento estiver bloqueado -->
              <template v-else-if="data.item.payment_lock">
                <p
                  id="setPendentCS"
                  @click="confirmPendentPayment(data.item.id)"
                >
                  Desbloquear Pagamento
                </p>
              </template>

              <!-- 2.5. Caso nenhuma das condições acima seja atendida -->
              <template v-else>
                <p>Já está bloqueado</p>
              </template>
            </template>
          </template>

          <!-- Ação separada: se a morada precisa ser validada manualmente -->
          <template
            v-if="
              data.item.status === 'processing' &&
              data.item.validation_address === 'manual'
            "
          >
            <p
              class="validateAddress"
              v-b-modal.modal-address
              @click="passDataModalValidate(data.item)"
            >
              Validar Morada
            </p>
          </template>
        </span>
      </template>
    </b-table>
    <!-- MODAL VALIDAÇÂO DE MORADA -->
    <b-modal
      ref="modal_address"
      id="modal-address"
      :title="'Validar Morada'"
      size="lg"
      hide-footer
    >
      <div class="form-group">
        <label for="addressInput">Morada:</label>
        <input
          type="text"
          class="form-control"
          id="addressInput"
          v-model="addressUpdate"
        />
      </div>
      <div class="form-group">
        <label for="cityInput">Cidade:</label>
        <input
          type="text"
          class="form-control"
          id="cityInput"
          v-model="cityUpdate"
        />
      </div>
      <div class="form-group">
        <label for="postalCodeInput">Código Postal:</label>
        <input
          type="text"
          class="form-control"
          id="postalCodeInput"
          v-model="postalCodeUpdate"
        />
      </div>
      <div class="form-group">
        <label for="stateInput">Estado:</label>
        <input
          type="text"
          class="form-control"
          id="stateInput"
          v-model="stateUpdate"
        />
      </div>
      <div class="form-group">
        <label for="exampleFormControlSelect1">País</label>
        <select
          class="form-control"
          id="exampleFormControlSelect1"
          v-model="countryUpdate"
        >
          <option
            v-for="country in countries"
            :key="country.code"
            :value="country.code"
          >
            {{ country.code }} ({{ country.name }})
          </option>
        </select>
        <div class="form-group">
          <label for="stateInput">Comentários:</label>
          <textarea class="form-control" rows="2" v-model="note"></textarea>
        </div>
      </div>
      <div class="google">
        <button class="btn btn-success" @click="openGoogle()">
          Abrir Google Maps
        </button>
      </div>
      <div class="google">
        <button
          type="submit"
          class="btn btn-primary"
          @click="approveValidationAddress()"
        >
          Validar Morada
        </button>
        <button class="btn btn-warning" @click="sendEmailToApproveAddress()">
          Enviar email ao cliente
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  data: function () {
    return {
      page: '',
      rows: '',
      perPage: '',
      currentPage: 1,
      rows2: '',
      perPage2: '',
      currentPage2: 1,
      filter: null,
      pickingOrders: [],
      processingOrders: [],
      pickingFilteredOrders: [],
      processingFilteredOrders: [],
      printOrders: [],
      note: '',
      fields: [
        {
          key: 'id',
          label: 'Nº Encmd.',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'date',
          label: 'Data',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle; width:10% ',
        },
        {
          key: 'articles',
          label: 'Artigos',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'client',
          label: 'Cliente',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'email',
          label: 'Email',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'company',
          label: 'Trans.',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'actions',
          label: 'Ações',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
      ],
      status: 'all',
      select_print: '',
      transportadora: '',
      emailSearch: '',
      addressUpdate: '',
      cityUpdate: '',
      postalCodeUpdate: '',
      stateUpdate: '',
      idOrderUpdate: '',
      countryUpdate: '',
      emailUpdate: '',
      firstName: '',
      secondName: '',
      phone: '',
      countries: [],
    };
  },
  methods: {
    //Gets
    async getOrdersPickingFun() {
      try {
        await this.$store.dispatch('getOrdersPicking');
        this.pickingOrders = this.getOrdersPicking;
        this.pickingFilteredOrders = this.pickingOrders;
        this.rows = this.pickingOrders.length;
        this.perPage = this.pickingOrders.length;
        console.log('AQUIII', this.pickingOrders);
      } catch (err) {
        console.log(err);
      }
    },
    async getProcessingOrders() {
      try {
        await this.$store.dispatch('getOrdersProcessing');
        this.processingOrders = this.getOrdersProcessing;
        this.processingFilteredOrders = this.processingOrders;
        this.rows2 = this.processingOrders.length;
        this.perPage2 = this.processingOrders.length;
        console.log('AQUIII 2', this.processingOrders);
      } catch (err) {
        console.log(err);
      }
    },
    //Core functions
    routeToOrder(orderId) {
      this.$parent.routeToOrder(orderId);
    },
    calculateDifferenceBetweenDates(date) {
      let diff = this.$parent.calculateDifferenceBetweenDates(date);
      return Number(diff);
    },
    isAutoCaptureLate(date) {
      const autoCaptureDate = new Date(date);
      const today = new Date();
      // Reset time part to midnight for both dates
      today.setHours(0, 0, 0, 0);
      autoCaptureDate.setHours(0, 0, 0, 0);
      return autoCaptureDate <= today;
    },
    splitDate(date) {
      let split = date.split('T');
      return split[0];
    },

    //Modais
    async passDataModal(data) {
      await this.$parent.passDataModal(data);
    },
    async passDataModalValidate(data) {
      console.log(data);
      this.dataModal = data;
      this.addressUpdate = data.adress1;
      this.cityUpdate = data.city;
      this.postalCodeUpdate = data.postcode;
      this.idOrderUpdate = data.id;
      this.stateUpdate = data.state;
      this.countryUpdate = data.country;
      this.emailUpdate = data.email;
      this.firstName = data.first_name;
      this.secondName = data.second_name;
      this.phone = data.phone;
    },
    async passContact(data) {
      await this.$parent.passContact(data);
    },
    async passProductData(data) {
      await this.$parent.passProductData(data);
    },
    async confirmPendent(data) {
      await this.$parent.confirmPendent(data);
    },
    async confirmPendentCS(data, bool) {
      await this.$parent.confirmPendentCS(data, bool);
    },
    async confirmPendentEdit(data) {
      await this.$parent.confirmPendentEdit(data);
    },
    async confirmPendentPayment(data) {
      await this.$parent.confirmPendentPayment(data);
    },
    async confirmPriority(data) {
      await this.$parent.confirmPriority(data);
    },
    async open_modal_company(data) {
      await this.$parent.open_modal_company(data);
    },
    async open_modal_delay(data) {
      await this.$parent.open_modal_delay(data);
    },
    async acceptOrderKK(data) {
      await this.$parent.acceptOrderKK(data);
    },
    async cancelOrderKK(data) {
      //Have to open the modal to choose the reason
      await this.$parent.openModalCancelKK(data);
    },
    //Specific functions
    async filterStatus() {
      this.emailSearch = '';
      if (this.status == 'all') {
        this.pickingFilteredOrders = this.pickingOrders;
        this.processingFilteredOrders = this.processingOrders;
      } else if (this.status == 'white') {
        this.pickingFilteredOrders = this.pickingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) < 2
        );
        this.processingFilteredOrders = this.processingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) < 2
        );
      } else if (this.status == 'blue') {
        this.pickingFilteredOrders = this.pickingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) == 2
        );
        this.processingFilteredOrders = this.processingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) == 2
        );
      } else if (this.status == 'orange') {
        this.pickingFilteredOrders = this.pickingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) == 3
        );
        this.processingFilteredOrders = this.processingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) == 3
        );
      } else if (this.status == 'red') {
        this.pickingFilteredOrders = this.pickingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) == 4
        );
        this.processingFilteredOrders = this.processingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) == 4
        );
      } else if (this.status == 'black') {
        this.pickingFilteredOrders = this.pickingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) > 4
        );
        this.processingFilteredOrders = this.processingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) > 4
        );
      }
    },
    async filterbyEmail() {
      if (this.emailSearch == '') {
        this.pickingFilteredOrders = this.pickingOrders;
        this.processingFilteredOrders = this.processingOrders;
      } else {
        this.pickingFilteredOrders = this.pickingFilteredOrders.filter(
          (order) => order.email.includes(this.emailSearch)
        );
        this.processingFilteredOrders = this.processingFilteredOrders.filter(
          (order) => order.email.includes(this.emailSearch)
        );
      }
    },
    async select_trans() {
      console.log(this.transportadora);
    },
    async selected() {
      if (this.select_print == '') {
        this.printOrders = [];
      } else {
        this.printOrders = [];
        for (let index = 0; index < Number(this.select_print); index++) {
          if (this.pickingFilteredOrders[index] != undefined)
            this.printOrders.push(this.pickingFilteredOrders[index]);
        }
      }
    },
    async checked(index, checked) {
      if (checked) {
        this.printOrders.push(this.pickingFilteredOrders[index]);
      } else {
        let index1 = await this.printOrders.findIndex(
          (order) => order.id == this.pickingFilteredOrders[index].id
        );
        if (index1 != -1) this.printOrders.splice(index1, 1);
      }
    },
    async imprimir() {
      let final = [];
      // let products = this.$parent.getProducts.data;
      this.$swal({
        title: 'Quer imprimir as encomendas selecionadas?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          for (const order of this.printOrders) {
            const obj = {
              id: order.id,
              first_name: order.first_name,
              second_name: order.second_name,
              adress1: order.adress1,
              adress2: order.adress2,
              city: order.city,
              state: order.state,
              postcode: order.postcode,
              country: order.country,
              shipping_company: order.shipping_company.split('-')[0],
              articles: [],
              total: order.total,
              coin: order.coin,
              customer_note: order.customer_note,
              impressao: true,
              difference: this.calculateDifferenceBetweenDates(order.date),
            };
            for (const prod of JSON.parse(order.articles)) {
              // let index = await products.findIndex((product) => product.sku == prod.sku);

              const obj_prod = {
                quantity: prod.quantity,
                name: prod.name,
                sku: prod.sku,
                ean: prod.ean,
              };
              obj.articles.push(obj_prod);
            }
            final.push(obj);
          }
          try {
            await this.$store.dispatch('printOrders', { orders: final });
            this.$parent.updateChilds();
            this.select_print = '';
          } catch (err) {
            console.log(err);
          }
        }
      });
    },
    async scrollProcessamento() {
      this.$refs['processamento'].scrollIntoView({ behavior: 'smooth' });
    },
    async openGoogle() {
      window.open(
        `https://www.google.com/maps/search/?api=1&basemap=satellite&query=${this.dataModal.adress1},${this.dataModal.city},${this.dataModal.state},${this.dataModal.postcode},${this.dataModal.country}`,
        '_blank'
      );
    },
    async allCountries() {
      try {
        await this.$store.dispatch('fetchAllCountries');
        this.countries = this.getAllCountries;
        console.log(this.getAllCountries);
      } catch (err) {
        alert(err);
      }
    },
    async approveValidationAddress() {
      console.log('Validar Morada');
      if (this.note != '') {
        let user = JSON.parse(localStorage.getItem('user'));
        let obj = {
          user: user.username,
          comment: this.note,
        };
        console.log(obj);
        console.log(this.notes);
        this.notes.push(obj);
        this.note = '';
      }
      try {
        await this.$store.dispatch('approveValidationAddress', {
          id: this.idOrderUpdate,
          address: this.addressUpdate,
          firstName: this.firstName,
          lastName: this.secondName,
          city: this.cityUpdate,
          postalCode: this.postalCodeUpdate,
          state: this.stateUpdate,
          country: this.countryUpdate,
          validateAddress: true,
          notes: this.note,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Morada ${this.idOrderUpdate} Aprovada`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs['modal_address'].hide();
        this.$parent.updateChilds();
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async sendEmailToApproveAddress() {
      console.log('Enviar email');
      try {
        await this.$store.dispatch('sendEmailToApproveAddress', {
          id: this.idOrderUpdate,
          address: this.addressUpdate,
          city: this.cityUpdate,
          postCode: this.postalCodeUpdate,
          state: this.stateUpdate,
          country: this.countryUpdate,
          email: this.emailUpdate,
          firstName: this.firstName,
          secondName: this.secondName,
          phone: this.phone,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Email enviado`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs['modal_address'].hide();
        this.$parent.updateChilds();
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  computed: {
    ...mapGetters(['getOrdersPicking']),
    ...mapGetters(['getOrdersProcessing']),
    ...mapGetters(['getAllCountries']),
  },
  async created() {
    await this.getOrdersPickingFun();
    await this.getProcessingOrders();
    await this.allCountries();
  },
};
</script>

<style>
#filters {
  padding: 0px 100px 0px 100px;
  text-align: left;
}
.table {
  font-size: 12px;
  margin-top: 30px;
}
#setPendentCS {
  color: rgb(0, 11, 168);
}
#setPendentCS:hover {
  text-decoration: underline;
  cursor: pointer;
}
#setPendent {
  color: rgb(255, 0, 0);
}
#setPendent:hover {
  text-decoration: underline;
  cursor: pointer;
}
#setPriority {
  color: rgb(26, 100, 2);
}
#setPriority:hover {
  text-decoration: underline;
  cursor: pointer;
}
#aUpdateAddress {
  margin-left: 5px;
  cursor: pointer;
}
#aUpdateAddress:hover {
  cursor: pointer;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
.search {
  margin: 30px 80px 0px 80px;
  text-align: center;
}
.search label {
  font-weight: bold;
}
.google {
  margin-top: 20px;
  text-align: center;
}
.validateAddress {
  color: rgb(158, 142, 53);
  cursor: pointer;
  padding: 6px;
  border: 2px solid gold;
}
.red-square {
  background-color: red;
  color: white;
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
}
.green-square {
  background-color: green;
  color: white;
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
}

.low-risk {
  color: green;
  font-weight: bold;
}

.medium-risk {
  color: orange;
  font-weight: bold;
}

.high-risk {
  color: red;
  font-weight: bold;
}

.btn-accept {
  background-color: green;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
}

.btn-cancel {
  background-color: red;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
}
</style>
