<template>
  <!-- TODO O TEMPLATE FEITO UTILIZANDO O BOOTSTRAP VUE -->
  <div class="productslist">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h1>Produtos</h1>
    <div class="row" id="filters">
      <div class="col">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">SKU/CNP</span>
          </div>
          <input
            type="text"
            class="form-control"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            v-model="skuSearch"
            @change="filterProducts()"
          />
        </div>
      </div>
      <div class="col">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">Nome</span>
          </div>
          <input
            type="text"
            class="form-control"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            v-model="nameSearch"
            @change="filterProducts()"
          />
        </div>
      </div>
      <div class="col">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">EAN</span>
          </div>
          <input
            type="text"
            class="form-control"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            v-model="eanSearch"
            @change="filterProducts()"
          />
        </div>
      </div>
      <div class="col" style="width: 100%">
        <div class="input-group input-group-sm mb-3">
          <!-- <div class="input-group-prepend"> -->
          <span class="input-group-text">Marca:</span>
          <!-- </div> -->
          <v-select
            :options="allBrands"
            v-model="selectedBrand"
            label="name"
            @input="filterProducts()"
            style="width: 70%"
          >
            <template slot="option" slot-scope="option"> {{ option.name }}</template>
          </v-select>
        </div>
      </div>
      <div class="col">
        <button class="btn btn-primary" @click="goToAdd()">Adicionar Produto</button>
        <button class="btn btn-success" v-b-modal.modal-join>Juntar Produtos</button>
      </div>
    </div>
    <br />
    <button class="btn btn-primary" @click="downFile()">Listagem</button>
    <a class="btn btn-primary" href="/products/names"> Relatório de Nomes</a>
    <a class="btn btn-primary" href="/products/validity"> Ver Validades</a>
    <a class="btn btn-primary" href="/products/brands/report"> Relatório Marcas</a>
    <a class="btn btn-primary" href="/products/manualPrice/report"> Relatório Preço Manual</a>
    <a class="btn btn-primary" href="/products/aerosol/report"> Relatório Produtos Aerosois</a>
    <!-- TABELA COM TODOS OS PRODUTOS -->
    <div class="table">
      <b-table
        striped
        hover
        :items="allFilteredProducts"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        thead-class="text-white"
        small
      >
        <template #cell(sku)="data">
          <!-- <b-link @click="routeToProduct(data.item.id_backoffice)">{{ data.item.sku[0] }}</b-link> -->
          <router-link :to="`product/${data.item.id_backoffice}`">{{ data.item.sku[0] }}</router-link>
          <p v-if="data.item.sku.length > 1">
            (
            <a v-for="prod in data.item.sku" :key="prod">
              <a v-if="prod != data.item.sku[0]">
                {{ prod }}
              </a>
            </a>
            )
          </p>
        </template>
        <template #cell(ean)="data">
          <p v-for="prod in data.item.ean" :key="prod">
            {{ prod }}
          </p>
        </template>
        <template #cell(info)="data">
          <p><b>Peso: </b>{{ data.item.weight }} g</p>
          <p v-if="data.item.validity != null"><b>Validade: </b>{{ splitDate(data.item.validity) }}</p>
          <p v-else><b>Validade: </b>Sem validade</p>
          <p></p>
        </template>
        <!-- VERIFICAÇÃO DAS UNIDADES DE STOCK -->
        <template #cell(stock)="data">
          <!-- DEPENDENDO DO NÚMERO DE STOCK SURGE UMA BOLA COM COR DIFERENTE
        MAIOR QUE 5 COR VERDE
        ENTRE 1 E 4 COR AMARELA
        IGUAL A 0 VERMELHO -->
          <span v-if="data.item.stock < 5 && data.item.stock > 0">
            <p>{{ data.item.stock }}</p>
            <span class="dot-yellow"></span>
            <p>{{ data.item.average_price }}</p>
          </span>
          <span v-else-if="data.item.stock <= 0"
            ><p>{{ data.item.stock }}</p>
            <span class="dot-red"></span>
            <p>{{ data.item.average_price }}</p>
          </span>
          <span v-else
            ><p>{{ data.item.stock }}</p>
            <span class="dot-green"></span>
            <p>{{ data.item.average_price }}</p>
          </span>
          <v-select
            :options="status"
            v-model="data.item.stock_status"
            label="stock_status"
            @input="updateStockStatus(data.item)"
          >
            <template slot="option" slot-scope="option"> {{ option.stock_status }}</template>
          </v-select>
        </template>
        <template #cell(ocp_stock)="data">
          <span
            v-if="
              data.item.ocp_stock == 'Indisponível' ||
              data.item.ocp_stock == 'Nao Disponivel' ||
              data.item.ocp_stock == '0' ||
              data.item.ocp_stock == 'Nao Comercializado'
            "
          >
            <p>{{ data.item.ocp_stock }}</p>
            <span class="dot-red"></span>
            <p>{{ data.item.ocp_price }} €</p>
          </span>
          <span v-else
            ><p>{{ data.item.ocp_stock }}</p>
            <span class="dot-green"></span>
            <p>{{ data.item.ocp_price }} €</p></span
          >
        </template>
        <template #cell(alvim_stock)="data">
          <span v-if="Number(data.item.alvim_stock) < 5 && Number(data.item.alvim_stock) > 0">
            <p>{{ data.item.alvim_stock }}</p>
            <span class="dot-yellow"></span>
            <p>{{ data.item.alvim_price }} €</p>
          </span>
          <span v-else-if="data.item.alvim_stock == 'Indisponível' || Number(data.item.alvim_stock) <= 0">
            <p>{{ data.item.alvim_stock }}</p>
            <span class="dot-red"></span>
            <p>{{ data.item.alvim_price }} €</p>
          </span>
          <span v-else
            ><p>{{ data.item.alvim_stock }}</p>
            <span class="dot-green"></span>
            <p>{{ data.item.alvim_price }} €</p></span
          >
        </template>
        <template #cell(gomes_stock)="data">
          <span v-if="Number(data.item.gomes_stock) < 5 && Number(data.item.gomes_stock) > 0">
            <p>{{ data.item.gomes_stock }}</p>
            <span class="dot-yellow"></span>
            <p>{{ data.item.gomes_price }} €</p>
          </span>
          <span v-else-if="data.item.gomes_stock == 'Indisponível' || Number(data.item.gomes_stock) <= 0">
            <p>{{ data.item.gomes_stock }}</p>
            <span class="dot-red"></span>
            <p>{{ data.item.gomes_price }} €</p>
          </span>
          <span v-else
            ><p>{{ data.item.gomes_stock }}</p>
            <span class="dot-green"></span>
            <p>{{ data.item.gomes_price }} €</p></span
          >
        </template>
        <template #cell(perelhal_stock)="data">
          <span v-if="Number(data.item.perelhal_stock) < 5 && Number(data.item.perelhal_stock) > 0">
            <p>{{ data.item.perelhal_stock }}</p>
            <span class="dot-yellow"></span>
            <p>{{ data.item.perelhal_price }} €</p>
          </span>
          <span v-else-if="data.item.perelhal_stock == 'Indisponível' || Number(data.item.perelhal_stock) <= 0">
            <p>{{ data.item.perelhal_stock }}</p>
            <span class="dot-red"></span>
            <p>{{ data.item.perelhal_price }} €</p>
          </span>
          <span v-else
            ><p>{{ data.item.perelhal_stock }}</p>
            <span class="dot-green"></span>
            <p>{{ data.item.perelhal_price }} €</p>
          </span>
        </template>
        <template #cell(martins_stock)="data">
          <span v-if="Number(data.item.martins_stock) < 5 && Number(data.item.martins_stock) > 0">
            <p>{{ data.item.martins_stock }}</p>
            <span class="dot-yellow"></span>
            <p>{{ data.item.martins_price }} €</p>
          </span>
          <span v-else-if="data.item.martins_stock == 'Indisponível' || Number(data.item.martins_stock) <= 0">
            <p>{{ data.item.martins_stock }}</p>
            <span class="dot-red"></span>
            <p>{{ data.item.martins_price }} €</p>
          </span>
          <span v-else
            ><p>{{ data.item.martins_stock }}</p>
            <span class="dot-green"></span>
            <p>{{ data.item.martins_price }} €</p></span
          >
        </template>
        <template #cell(nova_engel_stock)="data">
          <span v-if="Number(data.item.nova_engel_stock) < 5 && Number(data.item.nova_engel_stock) > 0">
            <p>{{ data.item.nova_engel_stock }}</p>
            <span class="dot-yellow"></span>
            <p>{{ data.item.nova_engel_price }} €</p>
          </span>
          <span v-else-if="data.item.nova_engel_stock == 'Indisponível' || Number(data.item.nova_engel_stock) <= 0">
            <p>{{ data.item.nova_engel_stock }}</p>
            <span class="dot-red"></span>
            <p>{{ data.item.nova_engel_price }} €</p>
          </span>
          <span v-else
            ><p>{{ data.item.nova_engel_stock }}</p>
            <span class="dot-green"></span>
            <p>{{ data.item.nova_engel_price }} €</p></span
          >
        </template>
      </b-table>
    </div>
    <b-modal ref="modal_join" id="modal-join" title="Juntar Produtos" hide-footer>
      <form v-on:submit.prevent="joinProducts()">
        <div class="form-group">
          <label for="contactInput">SKU (manter):</label>
          <input type="text" class="form-control" id="sku_original" v-model="sku_original" />
          <br />
          <label for="contactInput">SKU (juntar/eliminar):</label>
          <input type="text" class="form-control" id="sku_to_join" v-model="sku_to_join" />
        </div>
        <div id="btnUpdateAddress">
          <button class="btn btn-primary">Juntar</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
import exportFromJSON from 'export-from-json';
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      perPage: 20,
      currentPage: 1,
      criteria: '',
      products: [],
      rows: 1,
      skuSearch: '',
      nameSearch: '',
      eanSearch: '',
      loading: false,
      allFilteredProducts: [],
      sku_original: '',
      sku_to_join: '',
      allBrands: [],
      status: [
        'Infinity',
        'Normal',
        'Normalix',
        'Zero',
        'Discontinued',
        'MinC No',
        'MinC Low',
        'MinC Mid',
        'MinC High',
      ],
      fields: [
        {
          key: 'sku',
          label: 'SKU/CNP',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'ean',
          label: 'EAN',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'name',
          label: 'Nome',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'stock',
          label: 'Stock',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'info',
          label: 'Info',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'ocp_stock',
          label: 'OCP',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'alvim_stock',
          label: 'Alvim',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'gomes_stock',
          label: 'Gomes',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'perelhal_stock',
          label: 'Perelhal',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'martins_stock',
          label: 'Martins',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'nova_engel_stock',
          label: 'Nova Engel',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'price',
          label: 'Preço',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
      ],
      selectedBrand: '',
    };
  },
  methods: {
    async updateStockStatus(item) {
      this.loading = true;
      try {
        await this.$store.dispatch('updateStockStatus', {
          id: item.id_backoffice,
          status: item.stock_status,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Stock Status Atualizado',
          showConfirmButton: false,
          timer: 1500,
        });
        this.new = true;
      } catch (err) {
        alert(err);
      }
      this.loading = false;
    },
    splitDate(date) {
      let split = date.split('T');
      return split[0];
    },
    goToAdd() {
      this.$router.push(`addProduct`);
    },
    async joinProducts() {
      if (this.sku_original == '' || this.sku_to_join == '') {
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Preencha os campos',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.loading = true;
        try {
          await this.$store.dispatch('joinProducts', {
            sku_original: this.sku_original,
            sku_to_join: this.sku_to_join,
          });
          this.$swal({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Produtos Juntos',
            showConfirmButton: false,
            timer: 1500,
          });
          this.new = true;
          this.sku_original = '';
          this.sku_to_join = '';
          this.$refs['modal_join'].hide();
        } catch (err) {
          alert(err);
        }
        this.loading = false;
      }
    },
    routeToProduct(productId) {
      this.$router.push(`product/${productId}`);
    },
    /* FUNÇÃO ASSINCRONA QUE VAI À STORE BUSCAR OS PRODUTOS */
    async getAllProducts() {
      this.loading = true;
      try {
        await this.$store.dispatch('fetchProducts');
        this.products = this.getProducts.data;
        /* VERIFICA QUANTAS LINHAS TERÁ A TABELA */
        this.rows = this.products.length;
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    async filterProducts() {
      console.log(this.selectedBrand);
      if (
        this.skuSearch == '' &&
        this.nameSearch == '' &&
        this.eanSearch == '' &&
        (this.selectedBrand == '' || this.selectedBrand == null)
      ) {
        console.log('Não pesquisa');
        this.allFilteredProducts = [];
      } else {
        try {
          await this.$store.dispatch('searchProduct', {
            sku: this.skuSearch,
            name: this.nameSearch,
            ean: this.eanSearch,
            brand: this.selectedBrand.id,
          });
          this.allFilteredProducts = this.getFilterProducts;
          this.rows = this.allFilteredProducts.length;
          this.perPage = this.allFilteredProducts.length;
        } catch (err) {
          console.log(this.skuSearch, this.nameSearch, this.eanSearch);
          console.log(err);
          alert(err);
        }
      }
    },
    async downFile() {
      let today = new Date();
      let date_i = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      let array = [];
      for (const product of this.allFilteredProducts) {
        console.log(product);
        let obj = {
          cnp: product.sku[0],
          nome: product.name,
          stock: product.stock,
        };
        array.push(obj);
      }
      const fileName = `Produtos_${date_i}`;
      const exportType = exportFromJSON.types.csv;
      let data = array;
      exportFromJSON({ data, fileName, exportType });
    },
    async brands() {
      try {
        await this.$store.dispatch('getAllBrands');
        console.log(this.getBrands);
        this.allBrands = this.getBrands;
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async getProductSearch() {
      this.skuSearch = JSON.parse(localStorage.getItem('product'));
      console.log(this.skuSearch);
      if (this.skuSearch != null) {
        this.filterProducts();
        localStorage.removeItem('product');
      }
    },
  },
  computed: {
    ...mapGetters(['getProducts']),
    ...mapGetters(['getFilterProducts']),
    ...mapGetters(['getBrands']),
  },
  created() {
    /* NA CRIAÇÃO DA PÁGINA CHAMA A FUNÇÃO QUE RETORNA TODOS OS PRODUTOS */
    /*  this.getAllProducts(); */
    this.brands();
    this.getProductSearch();
  },
};
</script>

<style scoped>
.productslist {
  text-align: center;
}
.table {
  font-size: 12px;
  margin-top: 30px;
}

/* PONTOS ONDE SURGE A COR DO STOCK */
.dot-yellow {
  height: 20px;
  width: 20px;
  background-color: goldenrod;
  border-radius: 50%;
  display: inline-block;
}
.dot-red {
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.dot-green {
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.searchbar {
  position: absolute;
  right: 0px;
  width: 200px;
  margin: 10px 20px 0px 0px;
}
#filters {
  padding: 0px 100px 0px 100px;
}
.customPage {
  background-color: tomato;
}

h2 {
  margin-top: 20px;
}

.btn {
  margin: 0px 5px 0px 5px;
}
</style>
