<template>
  <div>
    <h1>Moradas Pendentes</h1>
    <b-table
      striped
      hover
      :items="pendentOrders"
      :fields="fields"
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      class="table"
      small
      thead-class="text-white"
    >
      <template #cell(id)="data">
        <b-link @click="routeToOrder(data.item.id)">
          <span
            v-if="data.item.priority == true"
            style="background-color: green; color: white"
            >{{ data.item.id }}</span
          >
          <span v-else>{{ data.item.id }}</span>
        </b-link>
      </template>
      <template #cell(date)="data">
        <span
          v-if="calculateDifferenceBetweenDates(data.item.date) == 2"
          style="background: blue; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 3"
          style="background: orange; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 4"
          style="background: red; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) > 4"
          style="background: black; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else style="background: white">
          {{ splitDate(data.item.date) }}
        </span>
        <br />
        <a
          v-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == true
          "
        >
          <div
            :class="{ 'red-square': isAutoCaptureLate(data.item.auto_capture) }"
          >
            Auto captura: {{ splitDate(data.item.auto_capture) }}
          </div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
        <a
          v-else-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == false
          "
        >
          <div class="green-square">Capturado</div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
      </template>
      <template #cell(articles)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span
          v-for="article in JSON.parse(data.item.articles)"
          :key="article.id"
        >
          <p>
            {{ article.quantity }} x {{ article.sku }} -
            <a
              id="openArticle"
              v-b-modal.modal-3
              @click="passProductData(article)"
              >{{ article.name }}
            </a>
            - {{ article.price_eur }} € -
            <a v-if="article.picked == article.ordered">(✔️)</a>
            <a v-else style="color: red"
              >(Pick: {{ article.picked }} |<a style="color: black"
                >| Stock: {{ article.stock }}</a
              >)</a
            >
          </p>
        </span>
      </template>
      <template #cell(client)="data">
        <span>
          <p>
            <b>Nome:</b> {{ data.item.first_name }}
            {{ data.item.second_name }}
            <br />
            {{ data.item.adress1 }} {{ data.item.adress2 }} <br />
            {{ data.item.city }} - {{ data.item.postcode }}<br />
            {{ data.item.state }} - {{ data.item.country
            }}<!-- <b-button
                    id="btnUpdateAddress"
                    v-b-modal.modal-1
                    @click="passDataModal(data.item)"
                    >✒️</b-button
                    > -->
            <a
              id="aUpdateAddress"
              v-b-modal.modal-1
              @click="passDataModal(data.item)"
              >✒️</a
            >
          </p>
        </span>
        <span> </span>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.item.email }} <br />
          {{ data.item.phone }} <br />
          <a
            id="aUpdateAddress"
            v-b-modal.modal-2
            @click="passContact(data.item)"
            >📱</a
          >
        </span>
      </template>
      <template #cell(company)="data">
        <span>
          <p>
            {{ data.item.shipping_company.split('-')[0] }}
          </p>
        </span>
      </template>
      <template #cell(actions)="data">
        <span>
          <span>
            <p
              v-if="
                data.item.status == 'processing' && data.item.cs_lock == true
              "
              id="setPendentCS"
              @click="confirmPendentCS(data.item.id, data.item.cs_lock)"
            >
              Desbloquear CS
            </p>
            <p v-else>Atualize a Morada</p>
          </span>
        </span>
      </template>
    </b-table>
    <h1>Edição Pendentes</h1>
    <b-table
      striped
      hover
      :items="pendentOrdersEdit"
      :fields="fields"
      :filter="filter"
      :per-page="perPage3"
      :current-page="currentPage"
      class="table"
      small
      thead-class="text-white"
    >
      <template #cell(id)="data">
        <b-link @click="routeToOrder(data.item.id)">
          <span
            v-if="data.item.priority == true"
            style="background-color: green; color: white"
            >{{ data.item.id }}</span
          >
          <span v-else>{{ data.item.id }}</span>
        </b-link>
      </template>
      <template #cell(date)="data">
        <span
          v-if="calculateDifferenceBetweenDates(data.item.date) == 2"
          style="background: blue; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 3"
          style="background: orange; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 4"
          style="background: red; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) > 4"
          style="background: black; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else style="background: white">
          {{ splitDate(data.item.date) }}
        </span>
        <br />
        <a
          v-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == true
          "
        >
          <div
            :class="{ 'red-square': isAutoCaptureLate(data.item.auto_capture) }"
          >
            Auto captura: {{ splitDate(data.item.auto_capture) }}
          </div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
        <a
          v-else-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == false
          "
        >
          <div class="green-square">Capturado</div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
      </template>
      <template #cell(articles)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span
          v-for="article in JSON.parse(data.item.articles)"
          :key="article.id"
        >
          <p>
            {{ article.quantity }} x {{ article.sku }} -
            <a
              id="openArticle"
              v-b-modal.modal-3
              @click="passProductData(article)"
              >{{ article.name }}
            </a>
            - {{ article.price_eur }} € -
            <a v-if="article.picked == article.ordered">(✔️)</a>
            <a v-else style="color: red"
              >(Pick: {{ article.picked }} |<a style="color: black"
                >| Stock: {{ article.stock }}</a
              >)</a
            >
          </p>
        </span>
      </template>
      <template #cell(client)="data">
        <span>
          <p>
            <b>Nome:</b> {{ data.item.first_name }}
            {{ data.item.second_name }}
            <br />
            {{ data.item.adress1 }} {{ data.item.adress2 }} <br />
            {{ data.item.city }} - {{ data.item.postcode }}<br />
            {{ data.item.state }} - {{ data.item.country
            }}<!-- <b-button
                    id="btnUpdateAddress"
                    v-b-modal.modal-1
                    @click="passDataModal(data.item)"
                    >✒️</b-button
                    > -->
            <a
              id="aUpdateAddress"
              v-b-modal.modal-1
              @click="passDataModal(data.item)"
              >✒️</a
            >
          </p>
        </span>
        <span> </span>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.item.email }} <br />
          {{ data.item.phone }} <br />
          <a
            id="aUpdateAddress"
            v-b-modal.modal-2
            @click="passContact(data.item)"
            >📱</a
          >
        </span>
      </template>
      <template #cell(company)="data">
        <span>
          <p>
            {{ data.item.shipping_company.split('-')[0] }}
          </p>
        </span>
      </template>
      <template #cell(actions)="data">
        <span>
          <span>
            <p
              v-if="
                data.item.status == 'processing' && data.item.edit_lock == true
              "
              id="setPendentCS"
              @click="confirmPendentEdit(data.item.id, data.item.edit_lock)"
            >
              Desbloquear Edição
            </p>
            <p v-else>Atualize a Morada</p>
          </span>
        </span>
      </template>
    </b-table>
    <h1>CS Pendentes</h1>
    <b-table
      striped
      hover
      :items="pendentOrdersCS"
      :fields="fields"
      :filter="filter"
      :per-page="perPage2"
      :current-page="currentPage"
      class="table"
      small
      thead-class="text-white"
    >
      <template #cell(id)="data">
        <b-link @click="routeToOrder(data.item.id)">
          <span
            v-if="data.item.priority == true"
            style="background-color: green; color: white"
            >{{ data.item.id }}</span
          >
          <span v-else>{{ data.item.id }}</span>
        </b-link>
      </template>
      <template #cell(date)="data">
        <span
          v-if="calculateDifferenceBetweenDates(data.item.date) == 2"
          style="background: blue; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 3"
          style="background: orange; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 4"
          style="background: red; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) > 4"
          style="background: black; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else style="background: white">
          {{ splitDate(data.item.date) }}
        </span>
        <br />
        <a
          v-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == true
          "
        >
          <div
            :class="{ 'red-square': isAutoCaptureLate(data.item.auto_capture) }"
          >
            Auto captura: {{ splitDate(data.item.auto_capture) }}
          </div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
        <a
          v-else-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == false
          "
        >
          <div class="green-square">Capturado</div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
      </template>
      <template #cell(articles)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span
          v-for="article in JSON.parse(data.item.articles)"
          :key="article.id"
        >
          <p>
            {{ article.quantity }} x {{ article.sku }} -
            <a
              id="openArticle"
              v-b-modal.modal-3
              @click="passProductData(article)"
              >{{ article.name }}
            </a>
            - {{ article.price_eur }} € -
            <a v-if="article.picked == article.ordered">(✔️)</a>
            <a v-else style="color: red"
              >(Pick: {{ article.picked }} |<a style="color: black"
                >| Stock: {{ article.stock }}</a
              >)</a
            >
          </p>
        </span>
      </template>
      <template #cell(client)="data">
        <span>
          <p>
            <b>Nome:</b> {{ data.item.first_name }}
            {{ data.item.second_name }}
            <br />
            {{ data.item.adress1 }} {{ data.item.adress2 }} <br />
            {{ data.item.city }} - {{ data.item.postcode }}<br />
            {{ data.item.state }} - {{ data.item.country
            }}<!-- <b-button
                    id="btnUpdateAddress"
                    v-b-modal.modal-1
                    @click="passDataModal(data.item)"
                    >✒️</b-button
                    > -->
            <a
              id="aUpdateAddress"
              v-b-modal.modal-1
              @click="passDataModal(data.item)"
              >✒️</a
            >
          </p>
        </span>
        <span> </span>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.item.email }} <br />
          {{ data.item.phone }} <br />
          <a
            id="aUpdateAddress"
            v-b-modal.modal-2
            @click="passContact(data.item)"
            >📱</a
          >
        </span>
      </template>
      <template #cell(company)="data">
        <span>
          <p>
            {{ data.item.shipping_company.split('-')[0] }}
          </p>
        </span>
      </template>
      <template #cell(actions)="data">
        <span>
          <span>
            <p
              v-if="
                data.item.status == 'processing' && data.item.cs_lock == true
              "
              id="setPendentCS"
              @click="confirmPendentCS(data.item.id, data.item.cs_lock)"
            >
              Desbloquear CS
            </p>
            <p v-else>Atualize a Morada</p>
          </span>
        </span>
      </template>
    </b-table>
    <h1>Pagamento Pendentes</h1>
    <b-table
      striped
      hover
      :items="pendentOrdersPayment"
      :fields="fields"
      :filter="filter"
      :per-page="perPage2"
      :current-page="currentPage"
      class="table"
      small
      thead-class="text-white"
    >
      <template #cell(id)="data">
        <b-link @click="routeToOrder(data.item.id)">
          <span
            v-if="data.item.priority == true"
            style="background-color: green; color: white"
            >{{ data.item.id }}</span
          >
          <span v-else>{{ data.item.id }}</span>
        </b-link>
      </template>
      <template #cell(date)="data">
        <span
          v-if="calculateDifferenceBetweenDates(data.item.date) == 2"
          style="background: blue; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 3"
          style="background: orange; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 4"
          style="background: red; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) > 4"
          style="background: black; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else style="background: white">
          {{ splitDate(data.item.date) }}
        </span>
        <br />
        <a
          v-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == true
          "
        >
          <div
            :class="{ 'red-square': isAutoCaptureLate(data.item.auto_capture) }"
          >
            Auto captura: {{ splitDate(data.item.auto_capture) }}
          </div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
        <a
          v-else-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == false
          "
        >
          <div class="green-square">Capturado</div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
      </template>
      <template #cell(articles)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span
          v-for="article in JSON.parse(data.item.articles)"
          :key="article.id"
        >
          <p>
            {{ article.quantity }} x {{ article.sku }} -
            <a
              id="openArticle"
              v-b-modal.modal-3
              @click="passProductData(article)"
              >{{ article.name }}
            </a>
            - {{ article.price_eur }} € -
            <a v-if="article.picked == article.ordered">(✔️)</a>
            <a v-else style="color: red"
              >(Pick: {{ article.picked }} |<a style="color: black"
                >| Stock: {{ article.stock }}</a
              >)</a
            >
          </p>
        </span>
      </template>
      <template #cell(client)="data">
        <span>
          <p>
            <b>Nome:</b> {{ data.item.first_name }}
            {{ data.item.second_name }}
            <br />
            {{ data.item.adress1 }} {{ data.item.adress2 }} <br />
            {{ data.item.city }} - {{ data.item.postcode }}<br />
            {{ data.item.state }} - {{ data.item.country
            }}<!-- <b-button
                    id="btnUpdateAddress"
                    v-b-modal.modal-1
                    @click="passDataModal(data.item)"
                    >✒️</b-button
                    > -->
            <a
              id="aUpdateAddress"
              v-b-modal.modal-1
              @click="passDataModal(data.item)"
              >✒️</a
            >
          </p>
        </span>
        <span> </span>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.item.email }} <br />
          {{ data.item.phone }} <br />
          <a
            id="aUpdateAddress"
            v-b-modal.modal-2
            @click="passContact(data.item)"
            >📱</a
          >
        </span>
      </template>
      <template #cell(company)="data">
        <span>
          <p>
            {{ data.item.shipping_company.split('-')[0] }}
          </p>
        </span>
      </template>
      <template #cell(actions)="data">
        <span>
          <span>
            <p
              v-if="
                data.item.status == 'processing' &&
                data.item.payment_lock == true
              "
              id="setPendentCS"
              @click="
                confirmPendentPayment(data.item.id, data.item.payment_lock)
              "
            >
              Desbloquear Pagamento
            </p>
            <p v-else>Atualize a Morada</p>
          </span>
        </span>
      </template>
    </b-table>
    <h1>Kuanto Kusta Pendentes</h1>
    <b-table
      striped
      hover
      :items="kuantoKustaOrders"
      :fields="fields"
      :filter="filter"
      :per-page="perPage2"
      :current-page="currentPage"
      class="table"
      small
      thead-class="text-white"
    >
      <template #cell(id)="data">
        <b-link @click="routeToOrder(data.item.id)">
          <span
            v-if="data.item.priority == true"
            style="background-color: green; color: white"
            >{{ data.item.id }}</span
          >
          <span v-else>{{ data.item.id }}</span>
        </b-link>
      </template>
      <template #cell(date)="data">
        <span
          v-if="calculateDifferenceBetweenDates(data.item.date) == 2"
          style="background: blue; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 3"
          style="background: orange; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 4"
          style="background: red; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) > 4"
          style="background: black; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else style="background: white">
          {{ splitDate(data.item.date) }}
        </span>
        <br />
        <a
          v-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == true
          "
        >
          <div
            :class="{ 'red-square': isAutoCaptureLate(data.item.auto_capture) }"
          >
            Auto captura: {{ splitDate(data.item.auto_capture) }}
          </div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
        <a
          v-else-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == false
          "
        >
          <div class="green-square">Capturado</div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
      </template>
      <template #cell(articles)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span
          v-for="article in JSON.parse(data.item.articles)"
          :key="article.id"
        >
          <p>
            {{ article.quantity }} x {{ article.sku }} -
            <a
              id="openArticle"
              v-b-modal.modal-3
              @click="passProductData(article)"
              >{{ article.name }}
            </a>
            - {{ article.price_eur }} € -
            <a v-if="article.picked == article.ordered">(✔️)</a>
            <a v-else style="color: red"
              >(Pick: {{ article.picked }} |<a style="color: black"
                >| Stock: {{ article.stock }}</a
              >)</a
            >
          </p>
        </span>
      </template>
      <template #cell(client)="data">
        <span>
          <p>
            <b>Nome:</b> {{ data.item.first_name }}
            {{ data.item.second_name }}
            <br />
            {{ data.item.adress1 }} {{ data.item.adress2 }} <br />
            {{ data.item.city }} - {{ data.item.postcode }}<br />
            {{ data.item.state }} - {{ data.item.country
            }}<!-- <b-button
                    id="btnUpdateAddress"
                    v-b-modal.modal-1
                    @click="passDataModal(data.item)"
                    >✒️</b-button
                    > -->
            <a
              id="aUpdateAddress"
              v-b-modal.modal-1
              @click="passDataModal(data.item)"
              >✒️</a
            >
          </p>
        </span>
        <span> </span>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.item.email }} <br />
          {{ data.item.phone }} <br />
          <a
            id="aUpdateAddress"
            v-b-modal.modal-2
            @click="passContact(data.item)"
            >📱</a
          >
        </span>
      </template>
      <template #cell(company)="data">
        <span>
          <p>
            {{ data.item.shipping_company.split('-')[0] }}
          </p>
        </span>
      </template>
      <template #cell(actions)="data">
        <span>
          <div v-if="data.item.status === 'processing' && data.item.kk_pending">
            <button @click="acceptOrderKK(data.item.id)" class="btn-accept">
              Aceitar
            </button>
            <button @click="cancelOrderKK(data.item.id)" class="btn-cancel">
              Cancelar
            </button>
          </div>
          <div v-else>
            <p>Atualize a Morada</p>
          </div>
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  data: function () {
    return {
      page: '',
      rows: '',
      perPage: 10,
      currentPage: 1,
      perPage2: 10,
      rows2: '',
      perPage3: 10,
      rows3: '',
      filter: null,
      pendentOrders: [],
      pendentOrdersCS: [],
      pendentOrdersEdit: [],
      pendentOrdersPayment: [],
      kuantoKustaOrders: [],
      fields: [
        {
          key: 'id',
          label: 'Nº Encmd.',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Data',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle; width:10% ',
          sortable: true,
        },
        {
          key: 'articles',
          label: 'Artigos',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'client',
          label: 'Cliente',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
        {
          key: 'email',
          label: 'Email',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
        {
          key: 'company',
          label: 'Trans.',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
        {
          key: 'actions',
          label: 'Ações',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async getOrdersPendentFunc() {
      try {
        await this.$store.dispatch('getOrdersPendent');
        await this.$store.dispatch('getOrdersPendentCS');
        await this.$store.dispatch('getOrdersPendentEdit');
        await this.$store.dispatch('getOrdersPendentPayment');
        await this.$store.dispatch('getOrdersPendentKK');
        this.pendentOrders = this.getOrdersPendent;
        this.pendentOrdersCS = this.getOrdersPendentCS;
        this.pendentOrdersEdit = this.getOrdersPendentEdit;
        this.pendentOrdersPayment = this.getOrdersPendentPayment;
        this.kuantoKustaOrders = this.getOrdersPendentKK;
        this.perPage = this.pendentOrders.length;
        this.rows = this.pendentOrders.length;
        this.perPage2 = this.pendentOrdersCS.length;
        this.rows2 = this.pendentOrdersCS.length;
      } catch (err) {
        console.log(err);
      }
    },
    routeToOrder(orderId) {
      this.$parent.routeToOrder(orderId);
    },
    calculateDifferenceBetweenDates(date) {
      let diff = this.$parent.calculateDifferenceBetweenDates(date);
      return Number(diff);
    },
    isAutoCaptureLate(date) {
      const autoCaptureDate = new Date(date);
      const today = new Date();
      // Reset time part to midnight for both dates
      today.setHours(0, 0, 0, 0);
      autoCaptureDate.setHours(0, 0, 0, 0);
      return autoCaptureDate <= today;
    },
    splitDate(date) {
      let split = date.split('T');
      return split[0];
    },

    //Modais
    async passDataModal(data) {
      await this.$parent.passDataModal(data);
    },
    async passContact(data) {
      await this.$parent.passContact(data);
      await this.getOrdersPendentFunc();
    },
    async passProductData(data) {
      await this.$parent.passProductData(data);
    },
    async confirmPendentCS(data, bool) {
      await this.$parent.confirmPendentCS(data, bool);
    },
    async confirmPendentEdit(data) {
      await this.$parent.confirmPendentEdit(data);
    },
    async confirmPendentPayment(data) {
      await this.$parent.confirmPendentPayment(data);
    },
    async acceptOrderKK(data) {
      await this.$parent.acceptOrderKK(data);
    },
    async cancelOrderKK(data) {
      await this.$parent.openModalCancelKK(data);
    },
  },
  computed: {
    ...mapGetters(['getOrdersPendent']),
    ...mapGetters(['getOrdersPendentCS']),
    ...mapGetters(['getOrdersPendentEdit']),
    ...mapGetters(['getOrdersPendentPayment']),
    ...mapGetters(['getOrdersPendentKK']),
  },
  async created() {
    await this.getOrdersPendentFunc();
  },
};
</script>

<style>
.table {
  font-size: 12px;
  margin-top: 30px;
}
#aUpdateAddress {
  margin-left: 5px;
  cursor: pointer;
}
#aUpdateAddress:hover {
  cursor: pointer;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
.low-risk {
  color: green;
  font-weight: bold;
}

.medium-risk {
  color: orange;
  font-weight: bold;
}

.high-risk {
  color: red;
  font-weight: bold;
}
.btn-accept {
  background-color: green;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
}

.btn-cancel {
  background-color: red;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
}
</style>
