<template>
  <div>
    <h1>Encomendas Prontas</h1>
    <b-table
      striped
      hover
      :items="ordersCompleted"
      :fields="fields"
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      class="table"
      small
      thead-class="text-white"
    >
      <template #cell(id)="data">
        <b-link @click="routeToOrder(data.item.id)">
          <span
            v-if="data.item.priority == true"
            style="background-color: green; color: white"
            >{{ data.item.id }}</span
          >
          <span v-else>{{ data.item.id }}</span>
        </b-link>
      </template>
      <template #cell(date)="data">
        <span
          v-if="calculateDifferenceBetweenDates(data.item.date) == 2"
          style="background: blue; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 3"
          style="background: orange; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 4"
          style="background: red; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) > 4"
          style="background: black; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else style="background: white">
          {{ splitDate(data.item.date) }}
        </span>
        <br />
        <a
          v-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == true
          "
        >
          <div
            :class="{ 'red-square': isAutoCaptureLate(data.item.auto_capture) }"
          >
            Auto captura: {{ splitDate(data.item.auto_capture) }}
          </div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
        <a
          v-else-if="
            data.item.auto_capture != undefined &&
            data.item.payment_required == false
          "
        >
          <div class="green-square">Capturado</div>
          <div
            v-if="data.item.risk_score != undefined"
            :class="{
              'low-risk': data.item.risk_score <= 10,
              'medium-risk':
                data.item.risk_score > 10 && data.item.risk_score <= 15,
              'high-risk': data.item.risk_score > 15,
            }"
          >
            Risk Score: {{ data.item.risk_score }}
            <span v-if="data.item.risk_score <= 10">(Low)</span>
            <span
              v-else-if="
                data.item.risk_score > 10 && data.item.risk_score <= 15
              "
              >(Medium)</span
            >
            <span v-else>(High)</span>
          </div>
        </a>
      </template>
      <template #cell(articles)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span
          v-for="article in JSON.parse(data.item.articles)"
          :key="article.id"
        >
          <p>
            {{ article.quantity }} x {{ article.sku }} -
            <a
              id="openArticle"
              v-b-modal.modal-3
              @click="passProductData(article)"
              >{{ article.name }}
            </a>
            - {{ article.price_eur }} € -
            <a v-if="article.picked == article.ordered">(✔️)</a>
            <a v-else style="color: red"
              >(Pick: {{ article.picked }} |<a style="color: black"
                >| Stock: {{ article.stock }}</a
              >)</a
            >
          </p>
        </span>
      </template>
      <template #cell(client)="data">
        <span>
          <p>
            <b>Nome:</b> {{ data.item.first_name }}
            {{ data.item.second_name }}
            <br />
            {{ data.item.adress1 }} {{ data.item.adress2 }} <br />
            {{ data.item.city }} - {{ data.item.postcode }}<br />
            {{ data.item.state }} - {{ data.item.country }}
            <!-- <b-button
              id="btnUpdateAddress"
              v-b-modal.modal-1
              @click="passDataModal(data.item)"
              >✒️</b-button
            > -->
            <br />
          </p>
        </span>
        <span> </span>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.item.email }} <br />
          {{ data.item.phone }} <br />
        </span>
      </template>
      <template #cell(company)="data">
        <span>
          <p>
            {{ data.item.shipping_company.split('-')[0] }}
          </p>
        </span>
      </template>
      <template #cell(validation_address)="data">
        <span v-if="data.item.validation_address == 'manual'">
          <button
            class="btn btn-primary"
            v-b-modal.modal-address
            @click="passDataModal(data.item)"
          >
            Validar
          </button>
        </span>
      </template>
    </b-table>
    <h1>Encomendas Não Prontas</h1>
    <b-table
      striped
      hover
      :items="ordersNormal"
      :fields="fields"
      :filter="filter"
      :per-page="perPage2"
      :current-page="currentPage"
      class="table"
      small
      thead-class="text-white"
    >
      <template #cell(id)="data">
        <b-link @click="routeToOrder(data.item.id)">
          <span
            v-if="data.item.priority == true"
            style="background-color: green; color: white"
            >{{ data.item.id }}</span
          >
          <span v-else>{{ data.item.id }}</span>
        </b-link>
      </template>
      <template #cell(date)="data">
        <span
          v-if="calculateDifferenceBetweenDates(data.item.date) == 2"
          style="background: blue; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 3"
          style="background: orange; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 4"
          style="background: red; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) > 4"
          style="background: black; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else style="background: white">
          {{ splitDate(data.item.date) }}
        </span>
      </template>
      <template #cell(articles)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span
          v-for="article in JSON.parse(data.item.articles)"
          :key="article.id"
        >
          <p>
            {{ article.quantity }} x {{ article.sku }} -
            <a
              id="openArticle"
              v-b-modal.modal-3
              @click="passProductData(article)"
              >{{ article.name }}
            </a>
            - {{ article.price_eur }} € -
            <a v-if="article.picked == article.ordered">(✔️)</a>
            <a v-else style="color: red"
              >(Pick: {{ article.picked }} |<a style="color: black"
                >| Stock: {{ article.stock }}</a
              >)</a
            >
          </p>
        </span>
      </template>
      <template #cell(client)="data">
        <span>
          <p>
            <b>Nome:</b> {{ data.item.first_name }}
            {{ data.item.second_name }}
            <br />
            {{ data.item.adress1 }} {{ data.item.adress2 }} <br />
            {{ data.item.city }} - {{ data.item.postcode }}<br />
            {{ data.item.state }} - {{ data.item.country }}
            <!-- <b-button
              id="btnUpdateAddress"
              v-b-modal.modal-1
              @click="passDataModal(data.item)"
              >✒️</b-button
            > -->
            <br />
          </p>
        </span>
        <span> </span>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.item.email }} <br />
          {{ data.item.phone }} <br />
        </span>
      </template>
      <template #cell(company)="data">
        <span>
          <p>
            {{ data.item.shipping_company.split('-')[0] }}
          </p>
        </span>
      </template>
      <template #cell(validation_address)="data">
        <span v-if="data.item.validation_address == 'manual'">
          <button
            class="btn btn-primary"
            v-b-modal.modal-address
            @click="passDataModal(data.item)"
          >
            Validar
          </button>
        </span>
      </template>
    </b-table>
    <b-modal
      ref="modal_address"
      id="modal-address"
      :title="'Validar Morada'"
      size="lg"
      hide-footer
    >
      <form v-on:submit.prevent="redirecttoValidation()">
        <div class="form-group">
          <label for="addressInput">Morada:</label>
          <input
            type="text"
            class="form-control"
            id="addressInput"
            v-model="addressUpdate"
          />
        </div>
        <div class="form-group">
          <label for="cityInput">Cidade:</label>
          <input
            type="text"
            class="form-control"
            id="cityInput"
            v-model="cityUpdate"
          />
        </div>
        <div class="form-group">
          <label for="postalCodeInput">Código Postal:</label>
          <input
            type="text"
            class="form-control"
            id="postalCodeInput"
            v-model="postalCodeUpdate"
          />
        </div>
        <div class="form-group">
          <label for="stateInput">Estado:</label>
          <input
            type="text"
            class="form-control"
            id="stateInput"
            v-model="stateUpdate"
          />
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect1">País</label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="countryUpdate"
          >
            <option
              v-for="country in countries"
              :key="country.code"
              :value="country.code"
            >
              {{ country.code }} ({{ country.name }})
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="stateInput">Comentários:</label>
          <textarea class="form-control" rows="2" v-model="note"></textarea>
        </div>
        <div class="google">
          <button class="btn btn-success btn-lg" @click="openGoogle()">
            Abrir GOOGLE MAPS
          </button>
        </div>
        <div class="selectBtn">
          <button
            type="submit"
            class="btn btn-primary"
            @click="redirecttoValidation('confirmar-morada')"
          >
            Confirmar Morada
          </button>
          <button
            type="submit"
            class="btn btn-warning"
            @click="redirecttoValidation('enviar-email')"
          >
            Enviar email ao cliente
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: function () {
    return {
      page: '',
      rows: '',
      perPage: 10,
      rows2: '',
      perPage2: 10,
      currentPage: 1,
      filter: null,
      validateAddressOrder: [],
      dataModal: [],
      addressUpdate: '',
      cityUpdate: '',
      postalCodeUpdate: '',
      stateUpdate: '',
      idOrderUpdate: '',
      countryUpdate: '',
      emailUpdate: '',
      firstName: '',
      secondName: '',
      phone: '',
      eanProduct: '',
      skuProduct: '',
      hsProduct: '',
      warehouseProduct: '',
      notesProduct: '',
      weightProduct: '',
      imgProduct: '',
      idProduct: '',
      stockProduct: '',
      countries: [],
      ordersCompleted: [],
      ordersNormal: [],
      lastName: '',
      note: '',
      notes: [],
      fields: [
        {
          key: 'id',
          label: 'Nº Encmd.',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Data',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle; width:10% ',
          sortable: true,
        },
        {
          key: 'articles',
          label: 'Artigos',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'client',
          label: 'Cliente',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
        {
          key: 'email',
          label: 'Email',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
        {
          key: 'company',
          label: 'Trans.',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
        {
          key: 'validation_address',
          label: 'Validar Morada',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
      ],
    };
  },
  methods: {
    async getOrdersValidateAddress() {
      console.log('WHERE');
      try {
        await this.$store.dispatch('getAllValidationAddress');
        this.validateAddressOrder = this.getAllOrdersValidateAddress;
        this.ordersCompleted = this.validateAddressOrder.filter(
          (order) => order.picking_done == true
        );
        this.ordersNormal = this.validateAddressOrder.filter(
          (order) => order.picking_done == false
        );
        this.perPage = this.ordersCompleted.length;
        this.rows = this.ordersCompleted.length;
        this.perPage2 = this.ordersNormal.length;
        this.rows2 = this.ordersNormal.length;
      } catch (err) {
        console.log(err);
      }
    },
    async passProductData(data) {
      await this.$parent.passProductData(data);
    },
    async passDataModal(data) {
      console.log(data);
      this.dataModal = data;
      this.addressUpdate = data.adress1;
      this.cityUpdate = data.city;
      this.postalCodeUpdate = data.postcode;
      this.idOrderUpdate = data.id;
      this.stateUpdate = data.state;
      this.countryUpdate = data.country;
      this.emailUpdate = data.email;
      this.firstName = data.first_name;
      this.secondName = data.second_name;
      this.phone = data.phone;
      if (data.notes != undefined && data.notes != null) {
        this.notes = data.notes;
      }
    },
    async openGoogle() {
      window.open(
        `https://www.google.com/maps/search/?api=1&basemap=satellite&query=${this.dataModal.adress1.replace(
          /\s*$/,
          ''
        )}, ${this.dataModal.city}, ${this.dataModal.state}, ${
          this.dataModal.postcode
        }, ${this.dataModal.country}`,
        '_blank'
      );
    },
    routeToOrder(orderId) {
      if (orderId == undefined) {
        this.$parent.routeToOrder(this.orderSearch);
      } else {
        this.$parent.routeToOrder(orderId);
      }
    },
    calculateDifferenceBetweenDates(date) {
      let diff = this.$parent.calculateDifferenceBetweenDates(date);
      return Number(diff);
    },
    isAutoCaptureLate(date) {
      const autoCaptureDate = new Date(date);
      const today = new Date();
      // Reset time part to midnight for both dates
      today.setHours(0, 0, 0, 0);
      autoCaptureDate.setHours(0, 0, 0, 0);
      return autoCaptureDate <= today;
    },
    splitDate(date) {
      let split = date.split('T');
      return split[0];
    },
    async allCountries() {
      try {
        await this.$store.dispatch('fetchAllCountries');
        this.countries = this.getAllCountries;
        console.log(this.getAllCountries);
      } catch (err) {
        alert(err);
      }
    },
    async redirecttoValidation(action) {
      if (action == 'enviar-email') {
        await this.sendEmailToApproveAddress();
      } else if (action == 'confirmar-morada') {
        await this.approveValidationAddress();
      }
    },
    async approveValidationAddress() {
      console.log('Validar Morada');
      if (this.note != '') {
        let user = JSON.parse(localStorage.getItem('user'));
        let obj = {
          user: user.username,
          comment: this.note,
        };
        console.log(obj);
        console.log(this.notes);
        this.notes.push(obj);
        this.note = '';
      }
      try {
        await this.$store.dispatch('approveValidationAddress', {
          id: this.idOrderUpdate,
          address: this.addressUpdate,
          firstName: this.firstName,
          lastName: this.secondName,
          city: this.cityUpdate,
          postalCode: this.postalCodeUpdate,
          state: this.stateUpdate,
          country: this.countryUpdate,
          validateAddress: true,
          notes: this.notes,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Morada ${this.idOrderUpdate} Aprovada`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs['modal_address'].hide();
        this.$parent.updateChilds();
        await this.getOrdersValidateAddress();
        await this.allCountries();
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async sendEmailToApproveAddress() {
      console.log('Enviar email');
      try {
        await this.$store.dispatch('sendEmailToApproveAddress', {
          id: this.idOrderUpdate,
          address: this.addressUpdate,
          city: this.cityUpdate,
          postCode: this.postalCodeUpdate,
          state: this.stateUpdate,
          country: this.countryUpdate,
          email: this.emailUpdate,
          firstName: this.firstName,
          secondName: this.secondName,
          phone: this.phone,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Email enviado`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$parent.updateChilds();
        this.$refs['modal_address'].hide();
        await this.getOrdersValidateAddress();
        await this.allCountries();
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  computed: {
    ...mapGetters(['getAllOrdersValidateAddress']),
    ...mapGetters(['getAllCountries']),
  },
  async created() {
    await this.getOrdersValidateAddress();
    await this.allCountries();
  },
};
</script>

<style scoped>
.google {
  margin-top: 30px;
  text-align: center;
}
.selectBtn {
  margin-top: 40px;
  text-align: center;
}
textarea {
  resize: none;
}
.low-risk {
  color: green;
  font-weight: bold;
}

.medium-risk {
  color: orange;
  font-weight: bold;
}

.high-risk {
  color: red;
  font-weight: bold;
}
</style>
